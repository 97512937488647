@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@200;300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap");

:root {
    color-scheme: light dark;
}

body {
    background-color: light-dark(#FFFFFF, #171a20)
}